import { HeadFC } from 'gatsby';
import React from 'react'
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Page8 from '../../components/InteractivePart/Page8/index';
import Feedback from '../../components/InteractivePart/Page2/Feedback/index';
import HomeEquity from '../../components/InteractivePart/Page7/HomeEquity/index'
import SubPageHero from '../../components/InteractivePart/Page8/SubPageHero';
import Form from '../../components/InteractivePart/Page8/Form/index';

const sizeClass = 'mx-auto';

function foundHouse() {
    return (
        <main>
            <Header className={sizeClass} />

            <div className={`container py-6 lg:py-16 ${sizeClass}`}>
                <section className="flex flex-col xl:flex-row-reverse gap-8 lg:gap-16">
                    <div className="flex-1">
                        <SubPageHero />
                    </div>
                </section>
            </div>
            <div className="chart bg-white">
                <section className={`container ${sizeClass}`}>
                    {/* <HomeEquity /> */}
                    <Form option={true}/>
                    <Feedback />
                </section>
            </div>

            <Footer className={sizeClass} />
        </main>
    )
}

export default foundHouse;
export const Head: HeadFC = () => <title>Nuborrow.com | Home Equity Made Easy</title>;



